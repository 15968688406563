import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { META_INFO } from '../interfaces/seo';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private title:Title,private meta:Meta,private route:ActivatedRoute) {}

  updateMeta(route: string){
    if(Object.prototype.hasOwnProperty.call(META_INFO, route)){
        const {title, desc} = META_INFO[route];
        this.updateTitle(title);
        this.updateDescription(desc);
        this.updateOpenGraph(title,desc,route);
    }
    this.updateRobot();
  }

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }
  updateOpenGraph(title: string,description: string,route:string) {
    if (title && description) {

      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.meta.updateTag({ name: 'twitter:description', content: description });

      this.meta.updateTag({ name: 'og:title', content: title });
      this.meta.updateTag({ name: 'og:description', content: description });
      this.meta.updateTag({ name: 'og:url', content: environment.domain + route.slice(1) });
    }
  }

  updateRobot(){
    this.route.queryParams.subscribe((params) => {
      const page = params['page'];
      const searchQuery = params['qry'];
      if (page || searchQuery) {
        this.meta.updateTag({name: 'robots', content: 'noindex, follow'});
      }
  });
}

}

<div class="main">
    <molla-page-header title="{{'returnPolicy'| translate}}" subtitle=""></molla-page-header>

    <nav class="breadcrumb-nav">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [href]="root.home()">{{'home' | translate}}</a>
                </li>
                <li class="breadcrumb-item active">{{'returnPolicy'| translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content m-3" *ngIf="selected==='en'">
        <div class="container" >
            <p>
                At aptools.sa, we aim to ensure customer satisfaction with our products. Below are the
                guidelines for returning products:
            </p><br>
            <h5>Returns:</h5>
            <ol>
                <li>
                    Products may be returned within 7 days from the date of receipt.
                </li>
                <li>
                    The product must have been supplied directly by Arab Pros Machinery
                    Equipment Co., be unused, undamaged, and remain in its original box.
                </li>
                <li>
                    All returned items will be inspected by Arab Pro to ensure they meet the return
                    criteria.
                </li>
            </ol>
            <h5>Non-Returnable Items:</h5>
            <ol>
                <li>
                    Chemical products with an expiration date may not be returned.
                </li>
                <li>
                    Products that were part of a promotion or included a free product sample are
                    not eligible for return.
                </li>
            </ol>
            <h5>Partial or Full Order Returns:</h5>
            <ol>
                <li>
                    Customers may choose to return all order or only part of it, depending on their
                    needs.
                </li>
            </ol>
            <h5>Return Fees and Conditions:</h5>
            <ol>
                <li>
                    If the return is due to a defect in the product, a manufacturer defect, damage
                    during delivery, or if the wrong product was delivered, no additional charges
                    for shipping or bank transfer fees will apply.
                </li>
                <li>
                    If the return is due to other reasons (e.g., the customer wishes to replace the
                    product or has changed his mind), shipping fees and bank transfer fees will be
                    applied.
                </li>
                <li>
                    <h6> Shipping Fees:</h6>
                    <ol>
                        <li>25 SAR for one-way shipping.</li>
                        <li>50 SAR (25 SAR + 25 SAR) for two-way shipping (shipping and return).</li>
                        <li>Shipping fees may vary depending on the area.</li>
                    </ol>
                </li>
            </ol>
            <h5>Payment Gateway Nonrefundable fees:</h5>
            <p>
                These fees are deducted by the payment gateways from the seller in cases of order
                cancellation or return
            </p>
            <ol>
                <li>
                    <b>Tabby:</b> A discount of 3.9% + 1 SAR of the order value.
                </li>
                <li><b>Tamara::</b> A discount of 6.99% + 1.5 SAR of the order value.</li>
                <li><b>Bank Cards:</b> Mada: 1.5% of the order value,Visa, MasterCard, and credit cards: 2.5% of the
                    order value</li>
                <li><b>Bank Transfer:</b> A discount ranging from 7 to 8.05 SAR per transfer.</li>
            </ol>
            <h5>Refusal of Delivery:</h5>
            <p>If a customer refuses to receive the product from the shipping company, the
                amount paid will be refunded after deducting the cost of shipping to the
                warehouse and any applicable bank transfer fees.</p>
            <h5>Policy Enforcement:</h5>
            <p>aptools.sa reserves the right to refuse any return that does not comply with its
                internal policies.</p>
        </div>
        <div class="mb-2"></div>
    </div>
    <div class="page-content m-3" *ngIf="selected==='ar'">
        <div class="container">
            <p>
                في متجر APTools.sa، نسعى لضمان رضا العملاء عن منتجاتنا. فيما يلي الإرشادات الخاصة بإرجاع المنتجات:
            </p><br>
            <h5>الإرجاع:</h5>
            <ol>
                <li>
                    يمكن إرجاع المنتجات خلال 7 أيام من تاريخ الاستلام.
                </li>
                <li>
                    يجب أن تكون المنتجات قد تم توريدها مباشرة من قبل شركة المحترفون العرب، غير مستعملة، غير تالفة، وبالعلبة الأصلية.
                </li>
                <li>
                    سيتم فحص جميع المنتجات المُعادة من قبل المحترفون العرب لضمان أنها تفي بمعايير الإرجاع.
                </li>
            </ol>
            <h5>المنتجات غير القابلة للإرجاع:</h5>
            <ol>
                <li>
                    لا يمكن إرجاع المنتجات الكيميائية التي لها تاريخ صلاحية.
                </li>
                <li>
                    المنتجات التي كانت جزءاً من عرض ترويجي أو تضمنت عينة منتج مجانية لا يمكن إرجاعها.
                </li>
            </ol>
            <h5>إرجاع الطلب جزئياً أو كلياً:</h5>
            <ol>
                <li>
                    يمكن للعملاء اختيار إرجاع جميع الطلب أو جزء منه، حسب احتياجاتهم.
                </li>
            </ol>
            <h5>رسوم وشروط الإرجاع:</h5>
            <ol>
                <li>
                    إذا كان الإرجاع بسبب عيب في المنتج، أو عيب من الشركة المصنعة، أو ضرر أثناء التوصيل، أو إذا تم تسليم منتج خطأ، 
                    فلن يتم تطبيق أي رسوم إضافية على الشحن أو رسوم التحويل البنكي.
                </li>
                <li>
                    إذا كان الإرجاع لأسباب أخرى (مثل رغبة العميل في استبدال المنتج أو تغيير رأيه)، ستُطبق رسوم الشحن ورسوم التحويل البنكي على العميل.
                </li>
                <li>
                    <h6>رسوم الشحن:</h6>
                    <ol>
                        <li>25 ريال سعودي للشحن من جهة واحدة.</li>
                        <li>50 ريال سعودي (25 ريال + 25 ريال) للشحن ذو الاتجاهين (الشحن والإرجاع).</li>
                        <li>قد تختلف رسوم الشحن حسب المنطقة.</li>
                    </ol>
                </li>
            </ol>
            <h5>الرسوم غير القابلة للاسترداد من بوابات الدفع:</h5>
            <p>
                تُخصم هذه الرسوم من قبل بوابات الدفع من البائع في حالات إلغاء الطلب أو الإرجاع.
            </p>
            <ol>
                <li>
                    <b>تابي:</b> خصم بنسبة 3.9% + 1 ريال سعودي من قيمة الطلب.
                </li>
                <li><b>تمارا:</b> خصم بنسبة 6.99% + 1.5 ريال سعودي من قيمة الطلب.</li>
                <li><b>بطاقات البنك:</b> مدا: 1.5% من قيمة الطلب، فيزا، ماستركارد، والبطاقات الائتمانية: 2.5% من قيمة الطلب.</li>
                <li><b>التحويل البنكي:</b> خصم يتراوح من 7 إلى 8.05 ريال سعودي لكل تحويل.</li>
            </ol>
            <h5>رفض التسليم:</h5>
            <p>
                إذا رفض العميل استلام المنتج من شركة الشحن، سيتم استرداد المبلغ المدفوع بعد خصم تكلفة الشحن إلى المستودع وأي رسوم تحويل بنكي قابلة للتطبيق.
            </p>
            <h5>تنفيذ السياسة:</h5>
            <p>
                يحتفظ موقع APTools.sa بالحق في رفض أي إرجاع لا يتوافق مع سياساته الداخلية.
            </p>
        </div>
        <div class="mb-2"></div>
    </div>
</div>